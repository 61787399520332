<template>
  <div>
    <page-header title='Cursos'></page-header>
    <v-row justify="end" class="mb-2" v-if="['super_admin', 'admin', 'director'].includes(user.role.name)">
      <!-- <v-btn color="white" depressed rounded class="mx-2 mr-10" @click="$router.push({name: 'NewCourse'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        NOVO CURSO
      </v-btn> -->
    </v-row>
    <v-card outlined style='border-radius: 18px' class="mt-3">
      <v-card-title class='primary white--text py-3 px-5'>
        <v-col cols='12' class='pa-0 mb-2'>
          <div class="caption">
            {{ $dayjs().format('dddd').toUpperCase() }}
          </div>
        </v-col>
        <v-col cols='12' class='pa-0'>
          <v-row class='px-3' justify='space-between'>
            <div class='title'>{{ $dayjs().format('DD [de] MMMM').toUpperCase() }}</div>
            <div class='headline font-weight-bold pr-8'>{{ $dayjs().format('YYYY')}}</div>
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-text class="mt-2 pa-0 px-2 pb-2">
        <course-list
          card_title="Cursos"
          default_title_calendar
          :use_with_emit="true"
          :current_year="current_year"
          @courseDetails="handleCourseDetails"
          @empityCourses="handleEmpityCourses"
        >
          <template v-slot:year_picker>
            <year-picker @selectedYear="handleSelectedYear" :year="current_year" :disabledPrevious="previousYear" :disabledNext="nextYear" />
          </template>

          <template v-slot:status_counter>
            <counter-course-status v-if="['super_admin', 'admin', 'director'].includes(user.role.name)" :current_year="current_year" class="px-3 mb-4 mt-2"/>
          </template>

        </course-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'CourseIndexList',

    components: {
      CounterCourseStatus: () => import('../counters/CounterCourseStatus.vue'),
      CourseList: () => import('./list/CourseList.vue'),
    },
    data() {
      return {
        selected_course_details: {},
        dialog: false,
        current_year: this.$dayjs().year(),
        year_aux: this.$dayjs().year(),
        previousYear: false,
        nextYear: false,
      }
    },
    methods: {
      handleEmpityCourses(data){
        if(data) {
          if(this.current_year >= this.year_aux) {
            this.nextYear = true
          }else{
            this.previousYear = true
          }
        }else{
          this.previousYear = false
          this.nextYear = false
        }
      },
      handleCourseDetails(e){
        this.selected_course_details = e
        this.dialog = true
      },
      handleCloseDialog(){
        this.selected_course_details = {}
        this.dialog = false
      },
      handleSelectedYear(e) {
        this.year_aux = this.current_year
        this.current_year = e
      }
    },

    computed: {
      ...mapState({
        user: state=> state.User.user
      })
    },
  }
</script>

<style scoped>
  >>> .v-dialog {
    overflow: hidden;
    border-radius: 15px;
  }
</style>